import React, { Component } from 'react';
import { connect } from 'react-redux'
import { activeAccordin } from '../../store/actions'

import Arrow from '../../assets/images/icon-arrow.svg'

class AccordionSection extends Component {
  onClick = () => {
    this.props.onClick(this.props.label);
    this.props.activeAccordin(this.props.parent, this.props.label)
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
      <div className={`cjd-accordin ${isOpen ? 'cjd-accordin-open' : 'cjd-accordin-close'}`}>
        <div onClick={onClick} className="cjd-label-wrapper" style={{ cursor: 'pointer' }}>
          {label}
          <div className={`cjd-icon-wrapper ${isOpen ? 'cjd-expanded' : 'cjd-collapsed'}`}>
            <img src={Arrow} alt={label} />
          </div>
        </div>
        {isOpen && (
          <div className="cjd-accordin-wrapper">
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  activeAccordin: (par, cur) => dispatch( activeAccordin(par, cur) )
})


export default connect(mapStateToProps, mapDispatchToProps)(AccordionSection);