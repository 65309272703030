import React from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { advanceOption } from '../../store/actions';

const Advance = ({ globals, advance, styles, advanceOption }) => {
  const {
    chestPocket,
    inserts,
    insertsCount,
    stripes,
    piping,
    proCuff,
    sleevesPiping,
  } = advance;

  return (
    <>
      {globals.bomber && (
        <div
          className="cjd-form-group"
          style={{ display: 'flex', marginTop: '10px', padding: '10px' }}
        >
          <span id="sleeve-pocket" style={{ marginRight: 'auto' }}>
            Remove Sleeve Pocket?
          </span>
          <Toggle
            icons={false}
            id="sleevePocket"
            defaultChecked={!advance.sleevePocket}
            aria-labelledby="sleeve-pocket"
            onChange={() =>
              advanceOption('sleevePocket', !advance.sleevePocket)
            }
          />
        </div>
      )}

      {!globals.hoodies && (
        <div
          className="cjd-form-group"
          style={{
            display: 'flex',
            marginTop: '10px',
            padding: '10px',
            borderTop: '1px solid #e6e6e6',
          }}
        >
          <span id="chest-pocket" style={{ marginRight: 'auto' }}>
            Add Chest Pocket
          </span>
          <Toggle
            icons={false}
            id="chestPocket"
            defaultChecked={chestPocket}
            aria-labelledby="chest-pocket"
            onChange={() => advanceOption('chestPocket', !chestPocket)}
          />
        </div>
      )}

      {!stripes &&
        !globals.bomber &&
        !globals.hoodies &&
        styles.sleeves !== 'Raglan' && (
          <div
            className="cjd-form-group"
            style={{
              display: 'flex',
              padding: '10px',
              marginTop: '20px',
              borderTop: '1px solid #e6e6e6',
            }}
          >
            <span id="shoulder-inserts" style={{ marginRight: 'auto' }}>
              Add Shoulder Inserts
            </span>
            <Toggle
              icons={false}
              id="shoulderInserts"
              defaultChecked={inserts}
              aria-labelledby="shoulder-inserts"
              onChange={() => advanceOption('inserts', !inserts)}
            />
          </div>
        )}

      {inserts && styles.sleeves !== 'Raglan' && (
        <div
          className="cjd-btn-group"
          style={{ marginTop: '20px', padding: '10px' }}
        >
          <div
            className={
              insertsCount === 1
                ? 'cjd-btn cjd-btn-primary'
                : 'cjd-btn cjd-btn-secondary'
            }
            onClick={() => advanceOption('insertsCount', 1)}
          >
            Single
          </div>
          <div
            className={
              insertsCount === 2
                ? 'cjd-btn cjd-btn-primary'
                : 'cjd-btn cjd-btn-secondary'
            }
            onClick={() => advanceOption('insertsCount', 2)}
          >
            Double
          </div>
        </div>
      )}

      {!inserts && styles.sleeves !== 'Raglan' && !globals.bomber && (
        <div
          className="cjd-form-group"
          style={{
            display: 'flex',
            padding: '10px',
            marginTop: '20px',
            borderTop: '1px solid #e6e6e6',
          }}
        >
          <span id="sleeves-stripe" style={{ marginRight: 'auto' }}>
            Add Sleeves Stripe
          </span>
          <Toggle
            icons={false}
            id="sleevesSripe"
            defaultChecked={stripes}
            aria-labelledby="sleeves-stripe"
            onChange={() => advanceOption('stripes', !stripes)}
          />
        </div>
      )}

      {!inserts && styles.sleeves === 'Raglan' && (
        <div
          className="cjd-form-group"
          style={{
            display: 'flex',
            padding: '10px',
            marginTop: '20px',
            borderTop: '1px solid #e6e6e6',
          }}
        >
          <span id="sleeves-piping" style={{ marginRight: 'auto' }}>
            Add Sleeves Piping
          </span>
          <Toggle
            icons={false}
            id="sleevesPiping"
            defaultChecked={sleevesPiping}
            aria-labelledby="sleeves-piping"
            onChange={() => advanceOption('sleevesPiping', !sleevesPiping)}
          />
        </div>
      )}

      {!globals.bomber && !globals.hoodies && (
        <div
          className="cjd-form-group"
          style={{
            display: 'flex',
            padding: '10px',
            marginTop: '20px',
            borderTop: '1px solid #e6e6e6',
          }}
        >
          <span id="piping" style={{ marginRight: 'auto' }}>
            {stripes
              ? 'Add Stripe Piping'
              : styles.pocket === 'Welt Pocket' ||
                styles.pocket === 'Slash Pocket'
              ? 'Add Shoulder & Pockets Piping'
              : 'Add Shoulder Piping'}
          </span>
          <Toggle
            icons={false}
            id="piping"
            defaultChecked={piping}
            aria-labelledby="piping"
            onChange={() => advanceOption('piping', !piping)}
          />
        </div>
      )}

      {!globals.hoodies && (
        <div
          className="cjd-form-group"
          style={{
            display: 'flex',
            padding: '10px',
            marginTop: '20px',
            borderTop: '1px solid #e6e6e6',
          }}
        >
          <span id="pro-cuff" style={{ marginRight: 'auto' }}>
            Want Pro Cuff?
          </span>
          <Toggle
            icons={false}
            id="proCuff"
            defaultChecked={proCuff}
            aria-labelledby="pro-cuff"
            onChange={() => advanceOption('proCuff', !proCuff)}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  advance: state.advance,
  globals: state.globals,
  styles: state.styles,
});

const mapDispatchToProps = (dispatch) => ({
  advanceOption: (key, val) => dispatch(advanceOption(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Advance);
