import React from 'react';
import { connect } from 'react-redux';

import { colorPicker } from '../../../store/actions';

const Hood = ({ globals, outside, inside, lace, colorPicker, flip }) => {
  return (
    <>
      {flip ? (
        <g
          className='cjd-color-hover'
          onClick={() => colorPicker('inside')}
          style={{ transform: 'translate(138px, 14px)' }}
        >
          <g id='hood-back'>
            <path
              id='back'
              d='M111.62,173.15c-50-3-79.44-98.23-106.86-94.76,15.32-17.15,28-56.52,38.83-62.87,14-6.27,40-8.15,80.29-10.34,40.3,2.19,66.29,4.07,80.28,10.34C215,21.87,227.67,61.24,243,78.39c-24.75-3.13-58.42,87.14-100.7,93.31-4.57.66-9.29,1.16-14.16,1.45Z'
              transform='translate(-4.58 -4.93)'
              fill={outside}
              stroke='#404041'
              strokeMiterlimit='2.61'
              strokeWidth='1'
              fillRule='evenodd'
            />
            <g id='line_left' data-name='line left'>
              <g>
                <polygon
                  points='89.14 162.98 89 161.99 89 161.99 89.01 161.99 89.01 161.99 89.14 162.98'
                  fill='#404041'
                />
                <polygon
                  points='88.72 159.99 88.45 157.99 88.44 157.96 88.5 157.95 88.51 157.98 88.76 159.99 88.76 160 88.72 160.01 88.72 159.99'
                  fill='#404041'
                />
                <polygon
                  points='88.18 155.99 87.91 153.98 87.9 153.93 88.01 153.91 88.02 153.97 88.26 155.97 88.27 156.02 88.18 156.03 88.18 155.99'
                  fill='#404041'
                />
                <polygon
                  points='87.65 151.98 87.39 149.97 87.38 149.9 87.54 149.88 87.55 149.95 87.78 151.96 87.79 152.03 87.66 152.04 87.65 151.98'
                  fill='#404041'
                />
                <polygon
                  points='87.14 147.97 86.89 145.96 86.88 145.86 87.08 145.84 87.09 145.94 87.32 147.95 87.33 148.04 87.15 148.06 87.14 147.97'
                  fill='#404041'
                />
                <polygon
                  points='86.64 143.96 86.41 141.95 86.39 141.82 86.64 141.8 86.66 141.92 86.87 143.93 86.89 144.04 86.66 144.07 86.64 143.96'
                  fill='#404041'
                />
                <polygon
                  points='86.18 139.94 85.95 137.93 85.94 137.8 86.21 137.77 86.22 137.9 86.44 139.91 86.45 140.04 86.19 140.07 86.18 139.94'
                  fill='#404041'
                />
                <polygon
                  points='85.74 135.92 85.52 133.91 85.51 133.77 85.79 133.74 85.81 133.88 86.01 135.89 86.03 136.03 85.75 136.06 85.74 135.92'
                  fill='#404041'
                />
                <polygon
                  points='85.31 131.9 85.11 129.89 85.09 129.74 85.39 129.71 85.41 129.86 85.61 131.87 85.62 132.02 85.33 132.05 85.31 131.9'
                  fill='#404041'
                />
                <polygon
                  points='84.91 127.88 84.72 125.87 84.7 125.71 85.02 125.68 85.03 125.84 85.22 127.85 85.23 128 84.92 128.03 84.91 127.88'
                  fill='#404041'
                />
                <polygon
                  points='84.53 123.86 84.34 121.84 84.33 121.68 84.66 121.65 84.68 121.81 84.85 123.83 84.87 123.99 84.54 124.02 84.53 123.86'
                  fill='#404041'
                />
                <polygon
                  points='84.16 119.83 83.99 117.81 83.98 117.64 84.33 117.61 84.34 117.79 84.51 119.8 84.52 119.97 84.18 120 84.16 119.83'
                  fill='#404041'
                />
                <polygon
                  points='83.82 115.8 83.66 113.79 83.65 113.6 84.02 113.57 84.03 113.76 84.18 115.77 84.2 115.95 83.84 115.98 83.82 115.8'
                  fill='#404041'
                />
                <polygon
                  points='83.5 111.77 83.35 109.75 83.34 109.56 83.72 109.53 83.74 109.73 83.88 111.74 83.89 111.93 83.52 111.96 83.5 111.77'
                  fill='#404041'
                />
                <polygon
                  points='83.21 107.74 83.07 105.72 83.06 105.52 83.46 105.5 83.47 105.7 83.6 107.71 83.61 107.91 83.22 107.94 83.21 107.74'
                  fill='#404041'
                />
                <polygon
                  points='82.94 103.7 82.81 101.69 82.8 101.48 83.21 101.45 83.23 101.66 83.34 103.68 83.36 103.88 82.95 103.91 82.94 103.7'
                  fill='#404041'
                />
                <polygon
                  points='82.69 99.67 82.57 97.65 82.56 97.43 83 97.41 83.01 97.63 83.11 99.65 83.13 99.86 82.7 99.88 82.69 99.67'
                  fill='#404041'
                />
                <polygon
                  points='82.46 95.63 82.36 93.61 82.35 93.39 82.8 93.37 82.81 93.59 82.91 95.61 82.92 95.83 82.47 95.86 82.46 95.63'
                  fill='#404041'
                />
                <polygon
                  points='82.27 91.59 82.18 89.57 82.17 89.34 82.64 89.32 82.65 89.56 82.73 91.57 82.74 91.81 82.28 91.83 82.27 91.59'
                  fill='#404041'
                />
                <polygon
                  points='82.1 87.55 82.02 85.53 82.01 85.29 82.5 85.28 82.51 85.52 82.58 87.54 82.58 87.78 82.11 87.79 82.1 87.55'
                  fill='#404041'
                />
                <polygon
                  points='81.95 83.51 81.9 81.49 81.89 81.24 82.39 81.23 82.39 81.48 82.45 83.5 82.46 83.75 81.96 83.76 81.95 83.51'
                  fill='#404041'
                />
                <polygon
                  points='81.85 79.47 81.82 77.45 81.81 77.21 82.29 77.2 82.3 77.44 82.34 79.46 82.35 79.7 81.86 79.72 81.85 79.47'
                  fill='#404041'
                />
                <polygon
                  points='81.79 75.43 81.77 73.41 81.76 73.17 82.22 73.17 82.23 73.4 82.26 75.42 82.26 75.66 81.79 75.66 81.79 75.43'
                  fill='#404041'
                />
                <polygon
                  points='81.75 71.39 81.75 69.36 81.74 69.14 82.19 69.14 82.19 69.36 82.21 71.38 82.21 71.61 81.75 71.61 81.75 71.39'
                  fill='#404041'
                />
                <polygon
                  points='81.75 67.34 81.76 65.32 81.76 65.11 82.19 65.11 82.19 65.32 82.19 67.34 82.19 67.56 81.75 67.56 81.75 67.34'
                  fill='#404041'
                />
                <polygon
                  points='81.78 63.3 81.8 61.28 81.81 61.07 82.22 61.08 82.22 61.28 82.2 63.3 82.2 63.51 81.77 63.51 81.78 63.3'
                  fill='#404041'
                />
                <polygon
                  points='81.84 59.26 81.89 57.23 81.89 57.04 82.28 57.05 82.28 57.24 82.24 59.26 82.24 59.47 81.84 59.46 81.84 59.26'
                  fill='#404041'
                />
                <polygon
                  points='81.94 55.21 82 53.19 82.01 53 82.39 53.02 82.38 53.2 82.33 55.22 82.32 55.42 81.93 55.41 81.94 55.21'
                  fill='#404041'
                />
                <polygon
                  points='82.08 51.17 82.16 49.15 82.17 48.97 82.53 48.99 82.52 49.17 82.44 51.19 82.44 51.37 82.07 51.36 82.08 51.17'
                  fill='#404041'
                />
                <polygon
                  points='82.25 47.13 82.35 45.11 82.36 44.94 82.7 44.96 82.7 45.13 82.6 47.15 82.59 47.33 82.24 47.31 82.25 47.13'
                  fill='#404041'
                />
                <polygon
                  points='82.46 43.09 82.58 41.08 82.59 40.91 82.92 40.93 82.91 41.1 82.8 43.11 82.79 43.28 82.45 43.26 82.46 43.09'
                  fill='#404041'
                />
                <polygon
                  points='82.72 39.06 82.86 37.04 82.87 36.89 83.18 36.91 83.17 37.06 83.04 39.08 83.03 39.24 82.71 39.22 82.72 39.06'
                  fill='#404041'
                />
                <polygon
                  points='83.02 35.03 83.18 33.01 83.19 32.87 83.48 32.89 83.47 33.04 83.32 35.05 83.31 35.2 83 35.18 83.02 35.03'
                  fill='#404041'
                />
                <polygon
                  points='83.36 31 83.54 28.99 83.56 28.85 83.83 28.87 83.82 29.01 83.64 31.02 83.63 31.17 83.34 31.14 83.36 31'
                  fill='#404041'
                />
                <polygon
                  points='83.74 26.97 83.95 24.96 83.97 24.83 84.23 24.86 84.21 24.99 84.01 27 84 27.14 83.73 27.11 83.74 26.97'
                  fill='#404041'
                />
                <polygon
                  points='84.18 22.95 84.42 20.95 84.43 20.83 84.66 20.86 84.65 20.98 84.43 22.98 84.42 23.11 84.16 23.08 84.18 22.95'
                  fill='#404041'
                />
                <polygon
                  points='84.68 18.94 84.94 16.94 84.96 16.85 85.14 16.87 85.13 16.96 84.88 18.97 84.87 19.07 84.66 19.05 84.68 18.94'
                  fill='#404041'
                />
                <polygon
                  points='85.23 14.94 85.52 12.94 85.53 12.87 85.67 12.89 85.66 12.96 85.39 14.96 85.37 15.04 85.22 15.02 85.23 14.94'
                  fill='#404041'
                />
                <polygon
                  points='85.83 10.94 86.16 8.95 86.16 8.9 86.25 8.92 86.24 8.96 85.94 10.96 85.93 11.01 85.82 11 85.83 10.94'
                  fill='#404041'
                />
                <polygon
                  points='86.49 6.95 86.84 4.96 86.85 4.94 86.88 4.95 86.88 4.96 86.55 6.96 86.55 6.99 86.49 6.98 86.49 6.95'
                  fill='#404041'
                />
                <polygon
                  points='87.21 2.98 87.39 2 87.22 2.98 87.22 2.99 87.21 2.99 87.21 2.98'
                  fill='#404041'
                />
              </g>
            </g>
            <g id='line_right' data-name='line right'>
              <g>
                <polygon
                  points='152.04 162.98 152.17 161.99 152.17 161.99 152.19 161.99 152.18 161.99 152.04 162.98'
                  fill='#404041'
                />
                <polygon
                  points='152.43 159.99 152.68 157.98 152.68 157.95 152.74 157.96 152.74 157.99 152.46 159.99 152.46 160.01 152.42 160 152.43 159.99'
                  fill='#404041'
                />
                <polygon
                  points='152.92 155.97 153.16 153.97 153.17 153.91 153.28 153.93 153.27 153.98 153 155.99 153 156.03 152.91 156.02 152.92 155.97'
                  fill='#404041'
                />
                <polygon
                  points='153.4 151.96 153.63 149.96 153.64 149.88 153.8 149.9 153.79 149.97 153.53 151.98 153.52 152.04 153.39 152.03 153.4 151.96'
                  fill='#404041'
                />
                <polygon
                  points='153.86 147.95 154.08 145.94 154.09 145.84 154.3 145.86 154.29 145.96 154.04 147.97 154.03 148.06 153.85 148.04 153.86 147.95'
                  fill='#404041'
                />
                <polygon
                  points='154.3 143.93 154.51 141.92 154.53 141.8 154.78 141.82 154.77 141.95 154.53 143.96 154.51 144.07 154.29 144.05 154.3 143.93'
                  fill='#404041'
                />
                <polygon
                  points='154.73 139.91 154.95 137.91 154.96 137.77 155.23 137.8 155.21 137.93 154.99 139.94 154.98 140.07 154.72 140.04 154.73 139.91'
                  fill='#404041'
                />
                <polygon
                  points='155.15 135.9 155.36 133.88 155.37 133.74 155.66 133.77 155.64 133.91 155.43 135.92 155.41 136.06 155.14 136.03 155.15 135.9'
                  fill='#404041'
                />
                <polygon
                  points='155.56 131.87 155.75 129.86 155.76 129.71 156.06 129.74 156.05 129.89 155.85 131.9 155.83 132.05 155.54 132.02 155.56 131.87'
                  fill='#404041'
                />
                <polygon
                  points='155.94 127.85 156.12 125.84 156.13 125.68 156.45 125.71 156.44 125.87 156.25 127.88 156.23 128.03 155.92 128.01 155.94 127.85'
                  fill='#404041'
                />
                <polygon
                  points='156.3 123.83 156.47 121.81 156.49 121.65 156.82 121.68 156.81 121.84 156.62 123.86 156.61 124.02 156.28 123.99 156.3 123.83'
                  fill='#404041'
                />
                <polygon
                  points='156.64 119.8 156.8 117.79 156.81 117.61 157.17 117.64 157.15 117.82 156.98 119.83 156.97 120 156.63 119.97 156.64 119.8'
                  fill='#404041'
                />
                <polygon
                  points='156.96 115.77 157.11 113.76 157.12 113.58 157.49 113.6 157.48 113.79 157.32 115.8 157.3 115.98 156.94 115.95 156.96 115.77'
                  fill='#404041'
                />
                <polygon
                  points='157.25 111.74 157.4 109.73 157.41 109.54 157.79 109.56 157.78 109.76 157.63 111.77 157.62 111.96 157.24 111.93 157.25 111.74'
                  fill='#404041'
                />
                <polygon
                  points='157.53 107.71 157.66 105.7 157.67 105.5 158.07 105.52 158.06 105.72 157.92 107.74 157.91 107.94 157.52 107.91 157.53 107.71'
                  fill='#404041'
                />
                <polygon
                  points='157.78 103.68 157.9 101.66 157.91 101.45 158.33 101.48 158.31 101.69 158.19 103.71 158.18 103.91 157.77 103.89 157.78 103.68'
                  fill='#404041'
                />
                <polygon
                  points='158.01 99.65 158.11 97.63 158.12 97.41 158.56 97.43 158.55 97.65 158.43 99.67 158.42 99.88 158 99.86 158.01 99.65'
                  fill='#404041'
                />
                <polygon
                  points='158.21 95.61 158.3 93.59 158.31 93.37 158.76 93.39 158.75 93.61 158.65 95.63 158.64 95.86 158.2 95.83 158.21 95.61'
                  fill='#404041'
                />
                <polygon
                  points='158.38 91.58 158.46 89.56 158.47 89.32 158.94 89.34 158.93 89.58 158.85 91.59 158.84 91.83 158.38 91.81 158.38 91.58'
                  fill='#404041'
                />
                <polygon
                  points='158.53 87.54 158.6 85.52 158.61 85.28 159.09 85.29 159.09 85.54 159.01 87.56 159 87.8 158.53 87.78 158.53 87.54'
                  fill='#404041'
                />
                <polygon
                  points='158.66 83.5 158.71 81.48 158.72 81.23 159.22 81.25 159.21 81.49 159.15 83.52 159.15 83.76 158.65 83.75 158.66 83.5'
                  fill='#404041'
                />
                <polygon
                  points='158.76 79.46 158.81 77.44 158.81 77.2 159.29 77.21 159.29 77.45 159.25 79.47 159.25 79.72 158.76 79.71 158.76 79.46'
                  fill='#404041'
                />
                <polygon
                  points='158.85 75.42 158.88 73.4 158.88 73.17 159.34 73.18 159.34 73.41 159.32 75.43 159.31 75.67 158.84 75.66 158.85 75.42'
                  fill='#404041'
                />
                <polygon
                  points='158.9 71.38 158.91 69.36 158.91 69.14 159.36 69.14 159.36 69.37 159.35 71.39 159.35 71.61 158.9 71.61 158.9 71.38'
                  fill='#404041'
                />
                <polygon
                  points='158.92 67.34 158.92 65.33 158.92 65.11 159.35 65.11 159.35 65.32 159.36 67.34 159.36 67.56 158.92 67.56 158.92 67.34'
                  fill='#404041'
                />
                <polygon
                  points='158.91 63.3 158.89 61.28 158.89 61.08 159.3 61.07 159.3 61.28 159.33 63.3 159.33 63.51 158.91 63.52 158.91 63.3'
                  fill='#404041'
                />
                <polygon
                  points='158.86 59.27 158.83 57.25 158.82 57.05 159.22 57.04 159.22 57.24 159.27 59.26 159.27 59.46 158.87 59.47 158.86 59.27'
                  fill='#404041'
                />
                <polygon
                  points='158.78 55.23 158.73 53.21 158.73 53.02 159.1 53.01 159.11 53.2 159.17 55.22 159.18 55.41 158.79 55.42 158.78 55.23'
                  fill='#404041'
                />
                <polygon
                  points='158.67 51.19 158.6 49.17 158.59 48.99 158.95 48.98 158.96 49.16 159.04 51.18 159.05 51.36 158.68 51.37 158.67 51.19'
                  fill='#404041'
                />
                <polygon
                  points='158.52 47.15 158.43 45.13 158.42 44.96 158.76 44.95 158.77 45.12 158.87 47.14 158.88 47.31 158.53 47.33 158.52 47.15'
                  fill='#404041'
                />
                <polygon
                  points='158.32 43.12 158.22 41.1 158.21 40.94 158.53 40.92 158.54 41.08 158.66 43.1 158.67 43.27 158.34 43.28 158.32 43.12'
                  fill='#404041'
                />
                <polygon
                  points='158.09 39.08 157.96 37.07 157.95 36.91 158.26 36.89 158.27 37.05 158.41 39.06 158.42 39.22 158.1 39.24 158.09 39.08'
                  fill='#404041'
                />
                <polygon
                  points='157.82 35.05 157.67 33.04 157.66 32.89 157.95 32.87 157.96 33.02 158.12 35.03 158.13 35.18 157.83 35.2 157.82 35.05'
                  fill='#404041'
                />
                <polygon
                  points='157.5 31.03 157.33 29.01 157.31 28.88 157.59 28.85 157.6 28.99 157.79 31 157.8 31.14 157.51 31.17 157.5 31.03'
                  fill='#404041'
                />
                <polygon
                  points='157.14 27 156.94 24.99 156.93 24.86 157.19 24.84 157.2 24.97 157.41 26.98 157.42 27.11 157.15 27.14 157.14 27'
                  fill='#404041'
                />
                <polygon
                  points='156.73 22.98 156.51 20.98 156.5 20.86 156.73 20.83 156.74 20.95 156.98 22.96 157 23.08 156.74 23.11 156.73 22.98'
                  fill='#404041'
                />
                <polygon
                  points='156.28 18.97 156.04 16.96 156.03 16.87 156.21 16.85 156.22 16.94 156.49 18.94 156.5 19.05 156.29 19.07 156.28 18.97'
                  fill='#404041'
                />
                <polygon
                  points='155.79 14.96 155.52 12.96 155.51 12.89 155.64 12.87 155.65 12.94 155.94 14.94 155.96 15.02 155.8 15.04 155.79 14.96'
                  fill='#404041'
                />
                <polygon
                  points='155.24 10.96 154.94 8.96 154.93 8.92 155.02 8.9 155.03 8.95 155.34 10.94 155.35 11 155.24 11.01 155.24 10.96'
                  fill='#404041'
                />
                <polygon
                  points='154.63 6.96 154.3 4.97 154.3 4.95 154.34 4.95 154.34 4.96 154.69 6.95 154.69 6.98 154.63 6.99 154.63 6.96'
                  fill='#404041'
                />
                <polygon
                  points='153.96 2.98 153.79 2 153.98 2.98 153.98 2.99 153.97 2.99 153.96 2.98'
                  fill='#404041'
                />
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g style={{ transform: 'translate(138px, 14px)' }}>
          <defs>
            <clipPath id='hoodFront' transform='translate(-564.56 -110.79)'>
              <path
                d='M675.8,209.26c-50-3-83.44-28.22-110.86-24.76,15.32-17.15,28-56.52,38.82-62.87,14-6.27,40-8.15,80.29-10.34,40.31,2.19,66.3,4.07,80.29,10.34,10.8,6.35,23.5,45.72,38.83,62.87-24.75-3.13-54.42,17.15-96.7,23.31-4.57.66-9.29,1.16-14.17,1.45Z'
                fill='none'
                clipRule='evenodd'
                strokeWidth='2'
              />
            </clipPath>
          </defs>
          <g id='collar_hood' data-name='collar hood'>
            <g clipPath='url(#hoodFront)'>
              <path
                d='M675.8,209.26c-50-3-83.44-28.22-110.86-24.76,15.32-17.15,28-56.52,38.82-62.87,14-6.27,40-8.15,80.29-10.34,40.31,2.19,66.3,4.07,80.29,10.34,10.8,6.35,23.5,45.72,38.83,62.87-24.75-3.13-54.42,17.15-96.7,23.31-4.57.66-9.29,1.16-14.17,1.45Z'
                transform='translate(-564.56 -110.79)'
                fill={outside ? outside : '#fff'}
                stroke='#231f20'
                strokeMiterlimit='2.61'
                strokeWidth='2'
                fillRule='evenodd'
                className='cjd-color-hover'
                onClick={() => colorPicker('outside')}
              />

              {/* <path
                d="M603.76,128.87c53.51-13.83,107-14.52,160.58,0,9.07,14.79-71.49,71.49-71.49,71.49-5.7,5.55-11.91,4.83-18.46,0C674.39,200.36,590.54,140,603.76,128.87Z"
                transform="translate(-564.56 -110.79)"
                fill={inside}
                stroke="#231f20"
                strokeMiterlimit="2.61"
                strokeWidth="1"
                fillRule="evenodd"
                className="cjd-color-hover"
                onClick={() => colorPicker('inside')}
              /> */}

              <path
                d='M38.82,17.58c53.51-13.83,107-14.52,160.58,0C208.47,32.37,126.61,98,126.61,98c-6.11,4.52-9.56,4.35-16.11-.48C110.5,97.5,25.6,28.71,38.82,17.58Z'
                transform='translate(0.75 1)'
                // transform="translate(-564.56 -110.79)"
                fill={inside}
                stroke='#231f20'
                strokeMiterlimit='2.61'
                strokeWidth='1'
                fillRule='evenodd'
                className='cjd-color-hover'
                onClick={() => colorPicker('inside')}
              />

              {/* <path d="M.24,1.26A897.54,897.54,0,0,0,113.6.26c-12.25,10.94-30.1,24.81-50.41,40C59.36,43,54.44,43,49.59,40.12,30.83,26.56,13.88,13.29.24,1.26Z" transform="translate(-0.24 -0.26)" fill={lining} style={{ transform: 'translate(63px, 50.6px)' }} className="cjd-color-hover" onClick={() => colorPicker('lining')}/> */}
            </g>
          </g>

          {(globals.hoodies || globals.coach) && (
            <g
              id='laces'
              style={{ transform: 'translate(80px, 80px)' }}
              className='cjd-color-hover'
              onClick={() => colorPicker('lace')}
            >
              <path
                d='M77,1.05a3.9,3.9,0,1,1-3.9,3.89A3.9,3.9,0,0,1,77,1.05Z'
                transform='translate(-0.72 -0.33)'
                fill='none'
                stroke='#231f20'
                strokeMiterlimit='2.61'
                strokeWidth='1'
              />
              <path
                d='M77,4.94c-5.89,41.87-1.6,85.24-6,122.32'
                transform='translate(-0.72 -0.33)'
                fill='none'
                stroke={lace}
                strokeLinecap='round'
                strokeMiterlimit='2.61'
                strokeWidth='2.44'
              />
              <path
                d='M4.92,1.07A3.9,3.9,0,1,1,1.46,5.36,3.9,3.9,0,0,1,4.92,1.07Z'
                transform='translate(-0.72 -0.33)'
                fill='none'
                stroke='#231f20'
                strokeMiterlimit='2.61'
                strokeWidth='1'
              />
              <path
                d='M5.33,4.94c-1.41,42.26,7.47,84.93,7,122.26'
                transform='translate(-0.72 -0.33)'
                fill='none'
                stroke={lace}
                strokeLinecap='round'
                strokeMiterlimit='2.61'
                strokeWidth='2.44'
              />
            </g>
          )}
        </g>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  globals: state.globals,
  colors: state.colors,
});

const mapDispatchToProps = (dispatch) => ({
  colorPicker: (part) => dispatch(colorPicker(part)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hood);
