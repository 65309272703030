import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { isLocalhost } from './utils';

import rootReducer from './store/reducers';
import { getProduct, setProduct } from './store/actions';

import './index.scss';
import App from './App';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk))
);

// Dev
// Varsity Jackets 953
// Bomber Jackets 942
// Hoodies 936
// Coach Jackets 928
// Varsity/Bomber Jacket 3002 With colors

// Production
// Varsity Jackets 5893
// Bomber Jackets 5944
// Hoodies 5995
// Coach Jackets 6046
// Varsity/Bomber Jacket 3002 With colors

window.mount = (productId = isLocalhost ? 5893 : 5893, data = false, cart = false) => {
  const target = document.getElementById('cjd-root');
  if (data) {
    store.dispatch(setProduct(productId, data, cart));
  } else {
    store.dispatch(getProduct(productId));
  }

  if (target) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>,
      target
    );
  }
};

if (isLocalhost) {
  window.mount();
}
