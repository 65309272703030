import React, { useState } from 'react';
import Modal from 'react-modal';

import identical from '../../assets/images/customize-team-identical-jackets.jpg';

const NewGuide = ({ modal, closeGuideModal, proceedAfterGuide }) => {
  const [cookie, setCookie] = useState(false);
  return (
    <Modal
      isOpen={modal}
      className="cjd-modal cjd-modal-guide"
      overlayClassName="cjd-modal-overlay"
      contentLabel={'Create Team (Identical) Jackets'}
      onRequestClose={closeGuideModal}
      ariaHideApp={false}
    >
      <header className="cjd-modal-header">
        <h4>
          Create Team (<span style={{ color: 'Tomato' }}>Identical</span>)
          Jackets
        </h4>
        <div className="cjd-modal-close" onClick={closeGuideModal}>
          {' '}
          ×{' '}
        </div>
      </header>

      <div className="cjd-modal-content guides">
        <img src={identical} alt="identical" width="500" />
        <label htmlFor="dontshow" className="once-cookie">
          <input
            type="checkbox"
            name="dontshow"
            id="dontshow"
            onChange={(e) => setCookie(e.target.checked)}
          />
          Don't show this again
        </label>
      </div>

      <div className="cjd-modal-footer">
        <div className="cjd-btn cjd-btn-secondary" onClick={closeGuideModal}>
          Cancel
        </div>
        <div
          className="cjd-btn cjd-btn-primary"
          onClick={() => proceedAfterGuide(cookie)}
        >
          Proceed
        </div>
      </div>
    </Modal>
  );
};

export default NewGuide;
