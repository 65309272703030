import React from 'react';
import { connect } from 'react-redux';
import WebFont from 'webfontloader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {
  currentTab,
  changePose,
  selectMaterial,
  activeSidebar,
  updateGlobals,
  modalState,
} from './store/actions';

import Header from './components/Header';

import Loader from './components/Loader';
import Jacket from './components/Jacket';
import JacketBack from './components/Jacket/back';
import JacketLeft from './components/Jacket/left';
import JacketRight from './components/Jacket/right';

import Coach from './components/coach';
import CoachBack from './components/coach/back';
import CoachLeft from './components/coach/left';
import CoachRight from './components/coach/right';

import Styles from './components/Styles';
import Materials from './components/Materials';
import Colors from './components/Colors';
import Designs from './components/Designs';
import Sizes from './components/Sizes';
import Advance from './components/Advance';
import PopUp from './components/Modal/popup';
import SaveDesign from './components/ModalSave';

import ViewFront from './assets/images/view-front.svg';
import ViewBack from './assets/images/view-back.svg';
import ViewSide from './assets/images/view-side.svg';

import './App.scss';

WebFont.load({
  google: {
    families: [
      'Source+Sans+Pro',
      'Courgette',
      'Cutive',
      'Graduate',
      'Lobster+Two',
      'Merienda+One',
      'Montserrat',
      'Open+Sans',
      'Oswald',
      'Pinyon+Script',
      'Satisfy',
    ],
  },
});

const App = ({
  globals,
  jackets,
  styles,
  materials,
  colors,
  designs,
  sizes,
  activeTab,
  changePose,
  popup,
  selectMaterial,
  pricing,
  activeSidebar,
  advance,
  updateGlobals,
  modalState,
  defaults,
}) => {
  if (globals.loading) return <Loader msg='Wait while we prepare the custom experience for you' />;

  return (
    <>
      <Header />

      <div className='cjd-main'>
        <div className='cjd-sidebar-wrapper'>
          <Tabs
            selectedIndex={globals.sidebar}
            onSelect={(tabIndex) => activeSidebar(tabIndex)}
            className='cjd-sidebar-wrapper'
          >
            <TabList className='cjd-tab-options'>
              {!globals.hoodies && (
                <Tab selectedClassName='cjd-active' className='cjd-tab-option cjd-material'>
                  Material
                </Tab>
              )}
              <Tab selectedClassName='cjd-active' className='cjd-tab-option cjd-styles'>
                Styles
              </Tab>
              {!globals.coach && (
                <Tab selectedClassName='cjd-active' className='cjd-tab-option cjd-advance'>
                  Advance
                </Tab>
              )}
              <Tab selectedClassName='cjd-active' className='cjd-tab-option cjd-colors'>
                Colors
              </Tab>
              <Tab selectedClassName='cjd-active' className='cjd-tab-option cjd-designs'>
                Designs
              </Tab>
              <Tab selectedClassName='cjd-active' className='cjd-tab-option cjd-sizes'>
                Sizes
              </Tab>
              <div className='cjd-tab-option cjd-save' onClick={() => modalState('save', true)}>
                Save
              </div>
              <div className='cjd-tab-option cjd-share' onClick={() => modalState('share', true)}>
                Share
              </div>
            </TabList>

            <div className='cjd-tab-content'>
              {!globals.hoodies && (
                <TabPanel>
                  <Materials />
                </TabPanel>
              )}
              <TabPanel>
                <Styles />
              </TabPanel>
              {!globals.coach && (
                <TabPanel>
                  <Advance />
                </TabPanel>
              )}
              <TabPanel>
                <Colors />
              </TabPanel>
              <TabPanel>
                <Designs />
              </TabPanel>
              <TabPanel>
                <Sizes />
              </TabPanel>
            </div>
          </Tabs>
        </div>

        <div className='cjd-content-wrapper'>
          {/* <pre>{JSON.stringify(designs, null, 2)}</pre> */}

          <div className='cjd-jacket-guides'>
            <span>Guides</span>
            <div
              href='#'
              className={`cjd-toggle-guides ${globals.guides && 'cjd-guides-on'}`}
              onClick={() => updateGlobals('guides', !globals.guides)}
            >
              {globals.guides ? 'ON' : 'OFF'}
            </div>
          </div>

          {/* <canvas id="customCanvas" className="rendererr"></canvas> */}

          {globals.coach ? (
            <>
              {globals.pose === 'front' && <Coach />}
              {globals.pose === 'back' && <CoachBack />}
              {globals.pose === 'left' && <CoachLeft />}
              {globals.pose === 'right' && <CoachRight />}
            </>
          ) : (
            <>
              <Jacket pose={globals.pose === 'front'} />
              <JacketBack pose={globals.pose === 'back'} />
              <JacketLeft pose={globals.pose === 'left'} />
              <JacketRight pose={globals.pose === 'right'} />
            </>
          )}

          <div className='cjd-jacket-nav'>
            <div
              className={`cjd-nav-item ${globals.pose === 'front' && 'cjd-active-nav'}`}
              onClick={() => changePose('front')}
            >
              <img src={ViewFront} alt='Front View' />
            </div>
            <div
              className={`cjd-nav-item ${globals.pose === 'back' && 'cjd-active-nav'}`}
              onClick={() => changePose('back')}
            >
              <img src={ViewBack} alt='Front Back' />
            </div>
            <div
              className={`cjd-nav-item ${globals.pose === 'left' && 'cjd-active-nav'}`}
              onClick={() => changePose('left')}
            >
              <img src={ViewSide} alt='Left Side' />
            </div>
            <div
              className={`cjd-nav-item ${globals.pose === 'right' && 'cjd-active-nav'}`}
              onClick={() => changePose('right')}
            >
              <img src={ViewSide} alt='Right Side' style={{ transform: 'scaleX(-1)' }} />
            </div>
          </div>
        </div>
      </div>

      <PopUp />
      <SaveDesign />
    </>
  );
};

const mapStateToProps = (state) => ({
  globals: state.globals,
  defaults: state.defaults,
  jackets: state.jackets,
  designs: state.designs,
  styles: state.styles,
  materials: state.materials,
  colors: state.colors,
  sizes: state.sizes,
  popup: state.popup,
  pricing: state.pricing,
  advance: state.advance,
});

const mapDispatchToProps = (dispatch) => ({
  activeTab: (tab) => dispatch(currentTab(tab)),
  changePose: (val) => dispatch(changePose(val)),
  selectMaterial: (val) => dispatch(selectMaterial(val)),
  activeSidebar: (idx) => dispatch(activeSidebar(idx)),
  updateGlobals: (key, val) => dispatch(updateGlobals(key, val)),
  modalState: (key, val) => dispatch(modalState(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
