import React from 'react';
import { connect } from 'react-redux';

import Accordion from '../Accordion';
import SelectBox from '../SelectBox';

import { rspace } from '../../utils';
import { selectMaterial } from '../../store/actions';

const Materials = ({ defaults, globals, materials, updateMaterial }) => (
  <>
    <Accordion>
      <div
        parent={0}
        label="Body Material"
        isOpen={globals[0] === 'Body Material'}
      >
        <div className="cjd-select-wrapper">
          {defaults.materials.map((val) => {
            if (val.body === 'on') {
              if (
                (val.name === 'Nylon' ||
                  val.name === 'Soft Shell' ||
                  val.name === 'Taslan Fabirc') &&
                globals.coach
              ) {
                return (
                  <SelectBox
                    key={val.id}
                    type={'body'}
                    label={val.name}
                    current={materials.body}
                    material={true}
                    className={`cjd-mat cjd-${rspace(val.name)}`}
                    dispatch={(type, label, sel) => updateMaterial(type, label)}
                  />
                );
              } else if (
                val.name !== 'Nylon' &&
                val.name !== 'Soft Shell' &&
                val.name !== 'Taslan Fabirc' &&
                globals.coach === false
              ) {
                return (
                  <SelectBox
                    key={val.id}
                    type={'body'}
                    label={val.name}
                    current={materials.body}
                    material={true}
                    className={`cjd-mat cjd-${rspace(val.name)}`}
                    dispatch={(type, label, sel) => updateMaterial(type, label)}
                  />
                );
              }
            } else {
              return true;
            }
          })}
        </div>
      </div>

      <div
        parent={0}
        label="Sleeve Material"
        isOpen={globals[0] === 'Sleeve Material'}
      >
        <div className="cjd-select-wrapper">
          {defaults.materials.map((val) => {
            if (materials.body === val['mat-parent'] && val.sleeves === 'on') {
              return (
                <SelectBox
                  key={val.id}
                  type={'sleeves'}
                  label={val.name}
                  current={materials.sleeves}
                  material={true}
                  className={`cjd-mat cjd-${rspace(val.name)}`}
                  dispatch={(type, label, sel) => updateMaterial(type, label)}
                />
              );
            } else {
              if (materials.body === val.name) {
                return (
                  <SelectBox
                    key={val.id}
                    type={'sleeves'}
                    label={val.name}
                    current={materials.sleeves}
                    material={true}
                    className={`cjd-mat cjd-${rspace(val.name)}`}
                    dispatch={(type, label, sel) => updateMaterial(type, label)}
                  />
                );
              } else {
                return true;
              }
            }
          })}
        </div>
      </div>
    </Accordion>
  </>
);

const mapStateToProps = (state) => ({
  defaults: state.defaults,
  globals: state.globals,
  materials: state.materials,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMaterial: (key, val) => dispatch(selectMaterial(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Materials);
